import React, { useState, useEffect } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import Header from './header';
import Footer from './footer';
import s from './layout.less';


export default function Layout (props) {
    useStyles(s);
    const { route } = props;
    const [showTop, setShowTop] = useState(false);

    const handleScroll = () => {// 滚动呈现
        setShowTop(window.pageYOffset >= 800);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            {
                route.chunks.indexOf('home') !== -1 ?
                    <div className="site-layout home">
                        <Header needFixed={route.chunks.indexOf('home') !== -1} />
                        <div className="page-main">
                            {route.component}
                            {showTop ?
                                <span className="go-top" onClick={() => document.body.scrollIntoView({ behavior: 'smooth' })}>
                                    <i className="iconfont icon-top">&#xe77c;</i>
                                </span> : null}
                        </div>
                        <Footer />
                    </div>
                    : <div className="site-layout">
                        <Header needFixed={route.chunks.indexOf('home') !== -1} />
                        <div className="page-main">
                            {route.component}
                            {showTop ?
                                <span className="go-top" onClick={() => document.body.scrollIntoView({ behavior: 'smooth' })}>
                                    <i className="iconfont icon-top">&#xe77c;</i>
                                </span> : null}
                        </div>
                        <Footer />
                    </div>

            }
        </>
    );
}