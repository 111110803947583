import React from 'react';
import Layout from './layout';

async function action ({ next }) {
    const route = await next();
    if (!route) return route;
    if (!route.redirect) {
        return {
            chunks: route.chunks.concat('layout'),
            title: route.title,
            description: route.description || '京日找房',
            component: <Layout route={route} />,
        };
    } else {
        return route;
    }
}

export default action;