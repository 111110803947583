import React, { useEffect, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './index.less'

export default function Footer () {
    useStyles(s);

    const url = typeof window !== 'undefined' ? window.location.pathname.replace(/[/]/g, '') : '';
    const isHome = url === '';
    // const isDetail = url.indexOf('detail') >= 0 ? true : false;
    // const isList = url.indexOf('list') >= 0 ? true : false;

    const nowDate = (new Date().toISOString()).slice(0, 10);
    const storageDate = typeof window !== 'undefined' ? localStorage.getItem('good_house_time') : '';
    const [showGoodHouse, setShowGoodHouse] = useState(false);

    useEffect(() => {
        let flag = false;
        if (!storageDate) {
            flag = true;
        } else if (nowDate !== storageDate) {
            localStorage.removeItem('good_house_time');
            flag = true;
        }
        setShowGoodHouse(flag);
    }, typeof window !== 'undefined' ? [window.location.href] : [])

    const closeGoodHouse = () => {
        localStorage.setItem('good_house_time', nowDate);
        setShowGoodHouse(false);
    };

    return (
        <>
            <div className="page-footer">
                <div className="page-footer-info" style={{ paddingBottom: isHome && showGoodHouse ? '134px' : '0px' }}>
                    Copyright © 2022  苏州京日找房信息科技有限公司 版权所有  <i /><a href="/policy" target="_blank">隐私协议</a><br />
                    <img src={require("../../../images/common/beian.png")} />
                    苏公网安备: <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32050702011016" target="_blank">32050702011016号</a>
                    网站备案号：<a href="https://beian.miit.gov.cn" target="_blank">苏ICP备2020056207号-2</a>
                </div>
            </div>
            {
                isHome && showGoodHouse ?
                    <div className="layer_good_house">
                        <div>
                            <img src={require("../../../images/common/home-bottom.png")} />
                            <img className="phone" src={require("../../../images/common/home-bottom-phone.png")} />
                            <img className="close" onClick={closeGoodHouse} src={require("../../../images/common/close.png")} />
                        </div>
                    </div> : null
            }

        </>
    )
}
