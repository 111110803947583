import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect, useState } from 'react';
import Link from '../../../components/Link';
import Login from '../../../components/login';
import s from './index.less';
import cookie from '../../../components/react-cookie';
import { CITYCODE_GET } from '../../../service/api';
import request from '../../../service/request';
import ChooseNowCityModal from '../../../pages/releaseHouse/components/chooseNowCity';

export default function Home (props) {
    useStyles(s);

    const url = typeof window !== 'undefined' ? window.location.pathname.replace(/[/]/g, '') : '';
    const [isHome, setIsHome] = useState({ show: false });
    const [navInfo, setNavInfo] = useState({
        list: ['首页', '二手房', '租房', '新房', '发布房源'],
        navSelected: ['必选好房', '店长力荐'],
        link: ['/', '/ershoufang/list', '/zufang/list', '/xinfang/list', '/releaseHouse'],
        bar: '-9999999px'
    });
    const [city, setCity] = useState({}); // 选择的城市
    const [cityListState, setCityListState] = useState(false); // 城市列表状态
    const navClickFun = (index) => {

        setNavInfo({
            ...navInfo,
            selected: index,
            bar: index > 4 ? '0px' : `${112 * index}px`
        });
    };

    const handleScrolls = () => {// 滚动呈现
        setIsHome({
            ...isHome,
            show: !navInfo.selected ? (window.pageYOffset >= 300) : false
        });
    };
    const [cityData, setCityData] = useState([]);

    const getCityList = async () => {
        await request.GET({
            url: CITYCODE_GET
        }).then((result) => {
            const siteList = result.data.cityList
            let list = [];
            for (let key in siteList) {
                list.push({
                    initials: key,
                    regionCityVOList: siteList[key]
                })
            }

            setCityData(list);
            let shortName = ''
            list.some((items, index) => {
                return items.regionCityVOList.some((itm) => {
                    if (cookie.load('cityCode') == itm.cityCode) {
                        shortName = itm.shortName;
                        return true
                    }
                })
            })
            setCity({
                cityCode: cookie.load('cityCode'),
                shortName
            });
            if (window.pageYOffset >= 160) {
                setCityListState(false);
            }
        })
    };

    useEffect(() => {
        getCityList();
    }, []);

    useEffect(() => {
        if (navInfo.selected == 0) {
            window.addEventListener('scroll', handleScrolls);
        } else {
            window.removeEventListener('scroll', handleScrolls);
        }
    }, [navInfo, setNavInfo]);

    useEffect(() => {

        let index = -1;
        if (url === '') {
            index = 0;
        } else if (url === 'policy' || url === 'protocol') {
            index = 0;
        } else if (url === 'focushouse') {
            index = 5;
        } else {
            index = [null, 'ershoufang', 'zufang', 'xinfang', 'releaseHouse'].findIndex(item => url.indexOf(item) >= 0);
        }

        if (index >= 0) {
            setNavInfo({
                ...navInfo,
                selected: index,
                bar: index > 0 && index < 5 ? `${112 * index}px` : '0px'
            });
        }

    }, typeof window !== 'undefined' ? [window.location.href, props.needFixed] : [props.needFixed]);

    useEffect(() => {
        document.addEventListener("click", otherDomClick);
    }, [cityListState, setCityListState]);

    const otherDomClick = (e) => {
        if (e.target.className != 'selectCity') {
            setCityListState(false);
        }
        if (e.target.className == 'location' || e.target.className == 'iconfont icon-ex city' || e.target.className == 'iconfont icon-arrow city') {
            setCityListState(!cityListState);
        }
    };

    const selectCity = (type, data) => {
        setCity({
            cityCode: data.cityCode,
            shortName: data.shortName,
        });
        cookie.save('cityCode', `${data.cityCode}`, { maxAge: 24 * 15 * 3600 * 1000, path: '/' });
        window.location.reload();
    };

    const cancelChangeCity = () => {
        setCityListState(!cityListState);
    }
    return (
        <>
            {
                props.needFixed ?
                    <>
                        <div className={isHome.show ? "page-header fixed" : "page-header"}>
                            <div className="nav-box">
                                <div className="nav" style={{ paddingTop: '20px' }}>
                                    <Link to="/">
                                        <img alt="" style={{ width: '175px' }} src={require(`../../../images/common/${isHome.show ? "page-logo.png" : "logo.png"}`)} />
                                    </Link>
                                    <span className={isHome.show ? "location public" : "location"}>
                                        <i className="iconfont icon-ex city">&#xe780;</i>
                                        {city.shortName}
                                        <i className="iconfont icon-arrow city">&#xe786;</i>
                                    </span>
                                    <ChooseNowCityModal visible={cityListState} cityList={cityData} onOk={selectCity} onCancel={cancelChangeCity}></ChooseNowCityModal>
                                    {/* <div className={cityListState ? "selectCity cityOn" : "selectCity"}>
                                        {cityData.map((items, index) => {
                                            return (
                                                <p key={index} className={items.cityCode == city.cityCode ? 'cityHide' : ''} onClick={(() => selectCity(items))}>
                                                    {items.shortName}
                                                </p>
                                            )
                                        })}
                                    </div> */}
                                </div>
                                <div style={{ width: '332px', float: 'right' }}>
                                    <Login isHome={!isHome.show} navInfo={navInfo.selected === 0 ? -1 : navInfo.selected} scrolls={handleScrolls} />
                                </div>
                                <div className={isHome.show ? "nav public" : "nav"}>
                                    <div className="nav-bar" style={{ left: navInfo.selected == 5 ? '-9999999px' : navInfo.bar }} />
                                    <ul className="page-nav">
                                        {navInfo.list.map((items, index) => {
                                            return (<li key={index} className={(navInfo.selected === index || (navInfo.selected > 4 && index === 0)) ? 'active' : ''}>
                                                <div className={index === 1 ? 'ershoufangOn' : ''}>
                                                    <Link to={navInfo.link[index]}>{items} {index === 1 ? <i className="iconfont icon-arrow">&#xe786;</i> : null}</Link>
                                                    <div className={isHome.show ? "" : "navInfoSelected"}>
                                                        <Link to={`${navInfo.link[index]}?houseType=1`}>{navInfo.navSelected[0]}</Link>
                                                        <Link to={`${navInfo.link[index]}?houseType=2`}>{navInfo.navSelected[1]}</Link>
                                                    </div>
                                                </div>
                                            </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div >
                        <div className="header-hr-content" />
                    </>
                    : <>
                        <div className="page-header">
                            <div className="nav-box">
                                <div className="nav" style={{ paddingTop: '20px' }}>
                                    <Link to="/" onClick={() => navClickFun(0)}>
                                        <img alt="" style={{ width: '175px' }} src={require("../../../images/common/page-logo.png")} />
                                    </Link>
                                    {
                                        navInfo.selected === 0 ?
                                            <span className="location public"><i className="iconfont icon-ex">&#xe780;</i>{city.shortName}</span> :
                                            <span></span>
                                    }
                                </div>
                                <div style={{ width: '332px', float: 'right' }}>
                                    <Login navInfo={navInfo.selected} scrolls={handleScrolls} />
                                </div>
                                <div className="nav public">
                                    <div className="nav-bar" style={{ left: navInfo.selected == 5 ? '-9999999px' : navInfo.bar }} />
                                    <ul className="page-nav">
                                        {navInfo.list.map((items, index) => {
                                            return (<li key={index} className={(navInfo.selected === index) ? 'active' : ''}>
                                                <Link to={navInfo.link[index]} onClick={() => navClickFun(index)}>{items}</Link>
                                            </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div >
                        <div className="header-hr-content" />
                    </>
            }
        </>
    )
}