import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import Link from '../Link';
import request from '../../service/request';
import { SENDCODE_POST, LOGIN_PHONE_SMS_POST, LOGIN_POST, RESET_PWD_POST, LOGOUT_POST, CUSETOMER_PERMISSION } from '../../service/api';
import Slider from '../slider';
import s from './index.less';


let sliderCaptchaUnReactive = false;

function Login (props) {
    const toast = typeof document !== 'undefined' && require('react-hot-toast').default;
    const { Toaster } = typeof document !== 'undefined' && require('react-hot-toast');
    useStyles(s);
    const firstUpdate = useRef(true);
    const loginTitle = ['手机快捷登录', '账号密码登录', '找回密码', '手机号码注册'];
    const loginBtn = ['账号密码登录', '手机快捷登录', '账号密码登录'];
    const [loginState, setLoginState] = useState(0);
    const [pwdState, setPwdState] = useState(0);
    const [loginInfo, setLoginInfo] = useState({
        smsCode: '',
        password: '',
        phone: '',
        platform: 6 // 平台
    });
    const [errorTip, setErrorTip] = useState('');
    const [modelState, setModelState] = useState(false);
    const [pageType, setPageType] = useState('');
    const [linkClass, setLinkClass] = useState('mine-follow');
    const [canClick, setCanClick] = useState(true);
    const [isSlider, setIsSlider] = useState(false); // 是否显示滑块验证
    const [sliderCaptcha, setSliderCaptcha] = useState(false); // 滑块验证是否通过
    useEffect(() => {
        sliderCaptchaUnReactive = sliderCaptcha;
    }, [sliderCaptcha])
    const timer = useRef(null);
    useEffect(() => {
        setPageType(window.location.pathname.replace(/[/]/g, ''));

        let linkCN = '';
        if (props.isHome) {
            linkCN = 'mine-follow white';
        } else {
            // linkCN = window.location.pathname.replace(/[/]/g, '') === 'focushouse' ? 'mine-follow active' : 'mine-follow';
            linkCN = 'mine-follow';
        }
        setLinkClass(linkCN);

    }, typeof window !== 'undefined' ? [props.isHome, window.location.href] : [props.isHome]);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        } else if (loginState !== 1) {
            document.getElementById("J-sms").innerHTML = "发送验证码";
        }
    }, [loginState, setLoginState]);

    const inputChangeFun = (e, type) => {
        const newObj = { ...loginInfo };
        newObj[type] = e.target.value;
        setLoginInfo(newObj);
    };

    // 重置
    const resetInfo = (type) => {
        setModelState(true);
        setLoginState(type);
        setLoginInfo({
            smsCode: '',
            password: '',
            phone: '',
            platform: 6 // 平台
        });
        setErrorTip('');
        setCanClick(true);
        setSliderCaptcha(false);
        setIsSlider(false);
        setPwdState(0);
        if (timer.current) {
            const spanDom = document.getElementById("J-sms");
            spanDom.innerHTML = "发送验证码";
            spanDom.className = "";
            clearInterval(timer.current);
        }
    };

    // 倒计时
    const timeTransition = () => {
        let count = 59;
        const spanDom = document.getElementById("J-sms");

        setCanClick(false);
        timer.current = setInterval(() => {
            if (count <= 1) {
                spanDom.innerHTML = "重新发送验证码";
                spanDom.className = "";
                clearInterval(timer.current);
                setCanClick(true);
                setSliderCaptcha(false);
            } else {
                spanDom.innerHTML = `${count--}s后重新发送`;
                spanDom.className = "style-class";
            }
        }, 1000);
    };

    // 验证
    const validationError = () => {
        let error = '';

        if (!(/^1\d{10}$/.test(loginInfo.phone))) {
            error = '请输入有效的手机号码';
            return error;
        }

        if (loginState !== 1) {
            if (!(/^\d{6}$/.test(loginInfo.smsCode))) {
                error = '请输入短信验证码';
                return error;
            }
        }
        if (loginState !== 0) {
            if (!loginInfo.password) {
                error = '请输入密码';
                return error;
            } else if (!(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/.test(loginInfo.password))) {
                error = '请输入6-16位数字和字母组合的密码';
                return error;
            }
        }
        return error;

    };

    // 关闭
    const closeModel = () => {
        setModelState(false);
        setIsSlider(false);
    };

    // 跳转
    const linkTo = () => {
        if (props.userInfo) {
            if (isHasVideoCreation) {
                window.open(process.env.REACT_CREATION_CENTER_URL)
            } else {
                return toast('您没有创作中心权限', {
                    duration: 2000,
                    icon: '⚠️'
                });
            }
        } else {
            setPageType("focushouse");
            resetInfo(0);
        }
    };

    // 跳转
    const linkToFocus = () => {
        if (props.userInfo) {
            window.location.href = "/focushouse";
        } else {
            setPageType("focushouse");
            resetInfo(0);
        }
    };

    const getSMS = () => {
        if (!canClick) return;
        if (!(/^1\d{10}$/.test(loginInfo.phone))) {
            setErrorTip('请输入有效的手机号码');
            return;
        } else {
            setErrorTip('');
        }
        if (!sliderCaptchaUnReactive) {
            setIsSlider(true);
            return;
        }
    };

    const sendSMS = () => {
        if (loginState == 1) {
            setIsSlider(false);
            loginSubmit();
        } else {
            request.POST({
                url: SENDCODE_POST,
                params: {
                    phone: loginInfo.phone,
                }
            }).then((res) => {
                if (res.code === 0 && res) {
                    setIsSlider(false);
                    timeTransition();
                }
            });
        }
    }

    const loginSubmit = () => {
        const errorInfo = validationError();
        if (errorInfo) {
            setErrorTip(errorInfo);
            return;
        } else {
            setErrorTip('');
        }

        let newParams = {
            phone: loginInfo.phone,
            platform: 6 // 平台
        };
        if (loginState !== 1) {
            newParams.smsCode = loginInfo.smsCode;
        }
        if (loginState !== 0) {
            newParams.password = loginInfo.password;
            if (loginState === 1) {
                if (!sliderCaptchaUnReactive) {
                    setIsSlider(true);
                    return;
                }
                newParams.type = 1;
            }
        }

        if (loginState >= 2) {
            newParams = loginInfo;
            newParams.type = 0;
        }

        request.POST({
            url: [LOGIN_PHONE_SMS_POST, LOGIN_POST, RESET_PWD_POST, LOGIN_POST][loginState],
            params: newParams
        }).then((res) => {
            if (res.code === 0) {
                if (loginState === 2) {
                    resetInfo(1);
                }
                // else if (pageType === 'focushouse') {
                //     closeModel();
                //     window.location.href = "/focushouse";
                // } 
                else {
                    closeModel();
                    window.location.reload();
                }
            } else {
                setErrorTip(res.errorMsg);
            }

        }).catch((error) => {
            console.log(error, 'error');
        });
    };

    const logoutSubmit = () => {
        request.POST({
            url: LOGOUT_POST
        }).then(() => {
            if (loginState === 2) {
                resetInfo(1);
            } else if (pageType === 'focushouse') {
                window.location.href = '/';
            } else {
                window.location.reload();
            }

        }).catch((error) => {
            console.log(error, 'error');
        });
    };

    const [isHasVideoCreation, setIsHasVideoCreation] = useState(false)
    useEffect(() => {
        if (props.userInfo) {
            request.GET({
                url: CUSETOMER_PERMISSION
            }).then((res) => {
                if (res.code == 0 && res.data.videoPermission == 1) {
                    setIsHasVideoCreation(true)
                }
            }).catch((error) => {
                console.log(error, 'error');
            });
        } else {
            setIsHasVideoCreation(false)
        }
    }, [props.userInfo])

    return (
        <>
            {props.userInfo ?
                <div className={props.isHome ? "user-box white" : "user-box"}>
                    <a className="mine-box" onClick={() => null}>
                        <img alt="" src={props.userInfo.avatar || require("../../images/common/agent-default-pic.png")} />
                        <div className="pop_show">
                            <div className="popover_content">
                                <div className="content_item" onClick={linkToFocus}>
                                    <div className="content_img"></div><div>我的关注</div>
                                </div>
                                <div className="divider"></div>
                                <div className="content_item" onClick={logoutSubmit}>
                                    <div className="content_img exit"></div><div>退出</div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                : <div className={props.isHome ? "login-box white" : "login-box"}>
                    <a onClick={() => resetInfo(0)}>
                        <img alt="" src={require("../../images/common/agent-default-pic.png")} />
                        <span className='header_nav_login_btn'>登录</span>
                    </a>
                    <i />
                    <a onClick={() => resetInfo(3)}>注册</a>
                </div>
            }
            <p className={linkClass}>
                <span onClick={linkTo}>创作中心 <i className="iconfont icon-arrow">&#xe786;</i></span>
                <div className="popup" style={{ display: 'none' }}>
                    <Link to="https://static.jingrizf.com/feresource/JRJJ.html" target="_blank">JR剪辑</Link>
                </div>
            </p>
            {modelState ?
                <div className="loginModel" id="appendWrap">

                    <div className="login_bg animated" onClick={closeModel} />

                    <div className="login_panel">
                        <div className="login_panel_close" onClick={closeModel}>×</div>
                        <div className="login_panel_box">
                            <div className="login_panel_label">
                                <h2 className="login_panel_title">{loginTitle[loginState]}</h2>
                                {loginState === 0 ? <div className="login_panel_tip">未注册手机将自动创建账号</div> : null}
                            </div>
                            <div className="input_box">
                                <input type="text" placeholder="请输入手机号" autoComplete="new-password"
                                    value={loginInfo.phone}
                                    onChange={(e) => inputChangeFun(e, 'phone')} />
                            </div>
                            {loginState !== 1 ?
                                <div className="input_box">
                                    <input type="text" className="input_info" placeholder="请输入短信验证码" maxLength={6} autoComplete="new-password"
                                        value={loginInfo.smsCode}
                                        onChange={(e) => inputChangeFun(e, 'smsCode')}
                                        style={{ width: '156px' }} />
                                    <a className="send_login_message_verify" onClick={getSMS}>
                                        <em id="J-sms">发送验证码</em>
                                    </a>
                                </div> : null}
                            {loginState !== 0 ?
                                <div className="input_box">
                                    <input type={pwdState ? "text" : "password"} className="input_info" placeholder="请输入密码" autoComplete="new-password"
                                        value={loginInfo.password}
                                        onChange={(e) => inputChangeFun(e, 'password')}
                                        style={{ width: '242px' }} />
                                    {pwdState ?
                                        <i className="iconfont password-view" onClick={() => {
                                            setPwdState(0);
                                        }}>&#xe7a9;</i>
                                        :
                                        <i className="iconfont password-view"
                                            onClick={() => {
                                                setPwdState(1);
                                            }}>&#xe7ac;</i>}
                                </div> : null}

                            {errorTip ? <div className="login_error_tip">{errorTip}</div> : null}
                            {loginState <= 1 ? <p>
                                登录即代表您已阅读并同意<a href="/policy" target="_blank">《隐私政策》</a><a href="/protocol" target="_blank">《用户协议》</a>
                            </p> : (loginState === 3 ? <p>
                                我已阅读并同意<a href="/policy" target="_blank">《隐私政策》</a>&nbsp;<a href="/protocol" target="_blank">《用户协议》</a>
                            </p> : null)}
                            <div className="login_submit" onClick={() => loginSubmit()}>{loginState === 2 ? '修改密码' : (loginState === 3 ? '注册' : '立即登录→')}</div>

                            {loginState === 3 ?
                                <div className="change_login_type register">已有账号? <a onClick={() => resetInfo(0)}>去登录</a></div>
                                :
                                <div className={loginState === 1 ? "change_login_type one" : (loginState === 2 ? "change_login_type two" : "change_login_type")}>
                                    <a onClick={() => resetInfo(loginState === 0 ? 1 : 0)}>{loginBtn[loginState]}</a>
                                    {loginState === 1 ? <a onClick={() => resetInfo(2)} className="last-item">忘记密码</a> : null}
                                </div>
                            }
                        </div>
                    </div>

                    <div />
                </div>
                : null}
            {
                isSlider ? <Slider setIsSlider={setIsSlider} setSliderCaptcha={setSliderCaptcha} sendSMS={sendSMS} /> : null
            }
            {
                Toaster && <Toaster />
            }
        </>
    )
}

const mapState = state => {
    return {
        userInfo: state.user.userInfo
    };
};

export default connect(mapState)(Login);